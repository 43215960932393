module.exports.baseUrl = {
  switch: process.env.REACT_APP_SWITCH_URL ? process.env.REACT_APP_SWITCH_URL : require('./local_environment').switchUrl,
  earth: process.env.REACT_APP_EARTH_URL ? process.env.REACT_APP_EARTH_URL : require('./local_environment').earthUrl,
  pluto: process.env.REACT_APP_PLUTO_URL ? process.env.REACT_APP_PLUTO_URL: require('./local_environment').plutoUrl,
  checkout: process.env.REACT_APP_CHECKOUT_URL ? process.env.REACT_APP_CHECKOUT_URL : require('./local_environment').checkoutUrl,
  paystack: process.env.REACT_APP_PAYSTACK_URL ? process.env.REACT_APP_PAYSTACK_URL : require('./local_environment').paystackUrl,
  dnaUrl: process.env.REACT_APP_DNA_URL ? process.env.REACT_APP_DNA_URL: require('./local_environment').dnaUrl,
  antar: process.env.REACT_APP_ANTAR_URL ? process.env.REACT_APP_ANTAR_URL: require('./local_environment').antarUrl,
  paymentEnvironemnt: process.env.REACT_APP_PAYMENT_ENVIRONMENT ? process.env.REACT_APP_PAYMENT_ENVIRONMENT: require('./local_environment').paymentEnvironemnt,
  marsUrl: process.env.REACT_APP_MARS_URL ? process.env.REACT_APP_MARS_URL: require('./local_environment').marsUrl,
  restApi: process.env.REACT_APP_REST_API_URL ? process.env.REACT_APP_REST_API_URL : require('./local_environment').restApiUrl
};

module.exports.envVar = {
  adyenClientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY ? process.env.REACT_APP_ADYEN_CLIENT_KEY : require('./local_environment').adyenClientKey,
  adyenPaymentEnvironemnt: process.env.REACT_APP_ADYEN_PAYMENT_ENVIRONMENT ? process.env.REACT_APP_ADYEN_PAYMENT_ENVIRONMENT : require('./local_environment').adyenPaymentEnvironemnt,
  gPayEnv: process.env.REACT_APP_GPAY_ENVIRONMENT ? process.env.REACT_APP_GPAY_ENVIRONMENT : require('./local_environment').gPayEnv,
  gPayPublishKey: process.env.REACT_APP_GPAY_PUBLISHKEY ? process.env.REACT_APP_GPAY_PUBLISHKEY : require('./local_environment').gPayPublishKey,
  gPayMerchantId: process.env.REACT_APP_GPAY_MERCHANT_ID ? process.env.REACT_APP_GPAY_MERCHANT_ID : require('./local_environment').gPayMerchantId,
  gPayMerchantName: process.env.REACT_APP_GPAY_MERCHANT_NAME ? process.env.REACT_APP_GPAY_MERCHANT_NAME : require('./local_environment').gPayMerchantName,
  gPaySupportedCardNetworks: process.env.REACT_APP_GPAY_SUPPORTED_CARD_NETWORKS ? process.env.REACT_APP_GPAY_SUPPORTED_CARD_NETWORKS : require('./local_environment').gPaySupportedCardNetworks,
  aPayMerchantIdentifier: process.env.REACT_APP_APAY_MERCHANT_ID ? process.env.REACT_APP_APAY_MERCHANT_ID : require('./local_environment').aPayMerchantIdentifier,
  aPayDomainName: process.env.REACT_APP_APAY_DOMAIN_NAME ? process.env.REACT_APP_APAY_DOMAIN_NAME : require('./local_environment').aPayDomainName,
  aPayMerchantDisplay: process.env.REACT_APP_APAY_MERCHANT_DISPLAY ? process.env.REACT_APP_APAY_MERCHANT_DISPLAY : require('./local_environment').aPayMerchantDisplay,
  aPayVersionNumber: process.env.REACT_APP_APAY_VERSION_NUMBER ? process.env.REACT_APP_APAY_VERSION_NUMBER : require('./local_environment').aPayVersionNumber,
  aPaySupportedCardNetworks: process.env.REACT_APP_APAY_SUPPORTED_CARD_NETWORKS ? process.env.REACT_APP_APAY_SUPPORTED_CARD_NETWORKS : require('./local_environment').aPaySupportedCardNetworks,
  spreedlyEnvironmentKey: process.env.REACT_APP_SPREEDLY_ENVIRONMENT_KEY ? process.env.REACT_APP_SPREEDLY_ENVIRONMENT_KEY : require('./local_environment').spreedlyEnvironmentKey,
  saturnBaseUrl: process.env.REACT_APP_SUPPER_REDIRECTION_URL ? process.env.REACT_APP_SUPPER_REDIRECTION_URL : require('./local_environment').saturnBaseUrl,  
  aPayAdyenMerchantIdentifier: process.env.REACT_APP_APAY_ADYEN_MERCHANT_ID ? process.env.REACT_APP_APAY_ADYEN_MERCHANT_ID : require('./local_environment').aPayAdyenMerchantIdentifier,
  gPayAdyenMerchantAccount: process.env.REACT_APP_GPAY_ADYEN_MERCHANT_ACCOUNT ? process.env.REACT_APP_GPAY_ADYEN_MERCHANT_ACCOUNT : require('./local_environment').gPayAdyenMerchantAccount,
}
